.Contacts{
    margin: 0;
    padding: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    background-color: #b8b8b84e;
    .wrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @media(max-width:1150px){
            padding: 50px 13vw;
        }
        @media(max-width:1000px){
            padding: 50px 10vw;
        }
        @media(max-width:650px){
            display: block;
        }
        .texts{
            padding: 20px;
            @media(max-width:900px){
                padding: 15px;
            }
            @media(max-width:800px){
                padding: 10px;
            }
            @media(max-width:650px){
                width: 100%;
            }
            .desc{
                color: white;
                font-size: 18px;
                text-align: left;
                font-weight: 300;
                @media(max-width:900px){
                    font-size: 16px;
                }
                @media(max-width:800px){
                    font-size: 15px;
                }
                @media(max-width:400px){
                    font-size: 14px;
                }
            }
            .title{
                color: white;
                font-size: 45px;
                text-align: left;
                @media(max-width:1100px){
                    font-size: 37px;
                }
                @media(max-width:900px){
                    font-size: 30px;
                }
                @media(max-width:800px){
                    font-size: 25px;
                }
                @media(max-width:400px){
                    font-size: 22px;
                }
            }
        }
        .form{
            padding: 20px;
            @media(max-width:900px){
                padding: 15px;
            }
            @media(max-width:800px){
                padding: 10px;
            }
            @media(max-width:650px){
                width: 100%;
            }
            .body{
                padding: 20px 30px;
                border-radius: 30px;
                border: solid 2px rgba(255, 255, 255, 0.386);
                @media(max-width:800px){
                    padding: 20px;
                    border-radius: 23px;
                }
                @media(max-width:400px){
                    border-radius: 19px;
                }
                .form-control{
                    border: none;
                    color: white;
                    padding: 10px 0;
                    border-radius: 0;
                    box-shadow: none;
                    background-color: transparent;
                    border-bottom: solid 2px rgba(255, 255, 255, 0.386);
                    &::placeholder{
                        color: #8D97A9;
                    }
                    @media(max-width:800px){
                        font-size: 15px;
                    }
                }
                input::-webkit-inner-spin-button,
                input::-webkit-outer-spin-button{
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type=number]{
                    -moz-appearance: 0;
                }
                .number{
                    margin-top: 30px;
                    @media(max-width:800px){
                        margin-top: 15px;
                    }
                }
                .red-line{
                    color: #E1053F;
                    border-bottom: solid 2px #E1053F;
                    &::placeholder{
                        color: #E1053F;
                    }
                }
                .send{
                    width: 100%;
                    border: none;
                    outline: none;
                    color: white;
                    padding: 13px 0;
                    margin-top: 30px;
                    background-color: #E1053F;
                    @media(max-width:800px){
                        padding: 8px 0;
                        font-size: 15px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}