.SearchProd{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 800px;
    display: flex;
    overflow: hidden;
    position: relative;
    padding-top: 100px;
    justify-content: center;
    @media (max-width:1500px){
        height: 700px;
    }
    @media (max-width:1200px){
        height: 600px;
    }
    @media (max-width:767px){
        height: 500px;
    }
    @media (max-width:600px){
        height: 450px;
        padding-top: 50px;
    }
    @media (max-width:450px){
        height: 400px;
    }
    .spinner{        
        width: 0;
        height: 0;
        z-index: 2;
        display: flex;
        position: absolute;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        // transition: 0.5s linear;
        background-color: white; 
        transition-timing-function: linear;       
        .body{            
            left: 0;
            padding: 25px;
            display: flex;
            max-width: 6vw;
            min-width: 6vw;
            max-height: 6vw;
            min-height: 6vw;
            position: absolute;  
            border-radius: 50%;
            align-items: center;
            // transition: 0.3s linear;
            justify-content: center;
            background-color: #E1053F;
            transition-timing-function: linear;
            @media (max-width:1400px){
                padding: 20px;                
            }
            @media (max-width:1200px){                
                max-width: 7vw;
                min-width: 7vw;
                max-height: 7vw;
                min-height: 7vw;
            }
            @media (max-width:767px){   
                padding: 15px;                
                max-width: 8vw;
                min-width: 8vw;
                max-height: 8vw;
                min-height: 8vw;
            }
            @media (max-width:600px){   
                padding: 12px;                
                max-width: 9vw;
                min-width: 9vw;
                max-height: 9vw;
                min-height: 9vw;
            }
            @media (max-width:420px){   
                padding: 10px;                
                max-width: 10vw;
                min-width: 10vw;
                max-height: 10vw;
                min-height: 10vw;
            }
            img{
                max-width: 100%;
                min-width: 100%;
                max-height: 100%;
                min-height: 100%;
                transition: 0.5s linear;
            }
        } 
        .active-body{
            max-width: 8vw;
            min-width: 8vw;
            max-height: 8vw;
            min-height: 8vw;
            @media (max-width:1200px){                
                max-width: 9vw;
                min-width: 9vw;
                max-height: 9vw;
                min-height: 9vw;
            }
            @media (max-width:767px){                
                max-width: 10vw;
                min-width: 10vw;
                max-height: 10vw;
                min-height: 10vw;
            }
            @media (max-width:600px){                
                max-width: 11vw;
                min-width: 11vw;
                max-height: 11vw;
                min-height: 11vw;
            }
            @media (max-width:420px){                
                max-width: 12vw;
                min-width: 12vw;
                max-height: 12vw;
                min-height: 12vw;
            }
        }     
        .texts{
            top: 0;
            width: 100%;
            height: 100%;
            padding: 25vw;
            display: flex;
            padding-top: 10vw;
            position: absolute;
            text-align: center;
            align-items: center;
            flex-direction: column;  
            transition: 0.5s linear;
            @media (max-width:600px){
                padding: 30vw;
                padding-top: 10vw;
            }
            @media (max-width:500px){
                padding: 27vw;
                padding-top: 10vw;
            }
            @media (max-width:400px){
                padding: 25vw;
                padding-top: 10vw;
            }
            .name{
                color: black;
                cursor: default;
                font-size: 3.3vw;
                font-weight: 700;
                text-align: center;
                font-family: 'Jura', sans-serif;
                @media (max-width:1200px){
                    font-size: 3.8vw;
                }
                @media (max-width:700px){
                    font-size: 4.5vw;
                }
                @media (max-width:500px){
                    font-size: 5.5vw;
                }                
            }
            .desc{
                font-size: 18px;
                cursor: default;
                margin-top: 4vw;
                max-width: 50vw;
                font-weight: 500;
                text-align: center;
                font-family: 'Jura', sans-serif;
                @media (max-width:1250px){
                    font-size: 16px;
                    max-width: 70vw;
                }
                @media (max-width:767px){
                    font-size: 13px;
                    max-width: 80vw;
                }
                @media (max-width:400px){
                    margin-top: 2vw;
                    font-size: 12px;
                }
            }
        }
        .mirror{
            top: -10%;
            left: -10%;
            width: 120%;
            height: 120%;
            position: absolute;
            background-color: transparent;
        }
        .btns{
            top: 0;
            left: 0;
            z-index: 4;
            width: 100%;
            height: 100%;
            padding: 20vw;
            display: flex;
            position: absolute;
            transition: 0.5s linear;
            align-items: flex-start;
            background-color: transparent;
            justify-content: space-between;
            button{
                width: 35px;
                border: none;
                height: 35px;
                outline: none;
                color: white;
                font-size: 25px;
                font-weight: 100;  
                border-radius: 50%;
                background: #8C8C8C;
                box-shadow: 0 0 0px 8px #8c8c8c4b;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                @media (max-width:700px){
                    width: 30px;
                    height: 30px;  
                    font-size: 22px;
                } 
                @media (max-width:500px){
                    width: 25px;
                    height: 25px;      
                    font-size: 18px;
                    box-shadow: 0 0 0px 6px #8c8c8c4b;   
                } 
            }
        }
    }
    .spinner-dots{
        left: 0;
        top: 33vw;
        margin: 0;
        z-index: 3;
        padding: 0;
        width: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        @media (max-width:1600px){
            top: 37vw;
        }
        @media (max-width:1400px){
            top: 44vw;
        }
        @media (max-width:1000px){
            top: 50vw;
        }
        @media (max-width:767px){
            top: 55vw;
        }
        @media (max-width:600px){
            top: 65vw;
        }
        @media (max-width:500px){
            top: 78vw;
        }
        @media (max-width:450px){
            top: 80vw;
        }
        .dot{
            padding: 0 10px;
            list-style: none;
            button{
                width: 11px;
                height: 11px;
                border: none;
                outline: none;
                transition: 0.3s;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.29);
                @media (max-width:600px){   
                    width: 8px;
                    height: 8px;
                }
            }
        }
        .dot-active{
            button{
                width: 28px;
                border-radius: 5px;
                background-color: black;
                @media (max-width:600px){   
                    width: 20px;
                }
            }
        }
        .next, .prev{
            width: 35px;
            border: none;
            height: 35px;
            outline: none;
            display: flex;
            color: white;
            font-size: 25px;
            font-weight: 100;  
            border-radius: 50%;
            position: absolute;
            align-items: center;
            background: #8C8C8C;
            justify-content: center;
            box-shadow: 0 0 0px 8px #8c8c8c4b;
            @media (max-width:700px){
                width: 30px;
                height: 30px;  
                font-size: 22px;
            } 
            @media (max-width:500px){
                width: 25px;
                height: 25px;      
                font-size: 18px;
                box-shadow: 0 0 0px 6px #8c8c8c4b;   
            } 
        }
        .next{
            right: 20%;
            @media (max-width:800px){
                right: 10%;
            }
            @media (max-width:450px){
                right: 5%;
            }
        }
        .prev{
            left: 20%;
            @media (max-width:800px){
                left: 10%;
            }
            @media (max-width:450px){
                left: 5%;
            }
        }
    }
}