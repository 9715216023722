.AboutHome{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
    .wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 200px;
        @media (max-width:800px){
            padding-bottom: 150px;
        }
        @media (max-width:420px){
            padding-bottom: 120px;
        }
        .text-card{
            padding: 0 5vw;
            position: relative;
            padding-top: 150px;
            @media(max-width:1100px){                            
                padding-top: 50px;
            }
            @media(max-width:1100px){  
                padding: 0 2vw;                          
                padding-top: 50px;
            }
            @media(max-width:600px){  
                padding: 0;
            }
            .body{
                padding: 60px 40px;
                padding-right: 50%;
                border-radius: 15px;
                background-color: #FDEAEA;
                @media(max-width:1400px){   
                    padding: 50px 40px;
                    padding-right: 45%;                         
                }
                @media(max-width:900px){   
                    padding: 30px;
                    padding-right: 45%;                         
                }
                @media(max-width:600px){   
                    padding: 30px;                                           
                }
                @media(max-width:400px){                                                          
                    padding: 20px;                    
                }
                .title{
                    margin: 0;
                    padding: 0;
                    font-weight: 700;
                    font-family: 'Jura', sans-serif;
                    @media(max-width:1250px){                            
                        font-size: 35px;
                    }
                    @media(max-width:500px){                            
                        font-size: 30px;
                    }
                }
                .desc{
                    font-size: 18px;
                    margin-top: 10px;
                    font-weight: 500;
                    margin-bottom: 40px;
                    font-family: 'Jura', sans-serif;
                    @media(max-width:1500px){                            
                        font-size: 16px;
                    }
                    @media(max-width:1250px){                            
                        font-size: 14px;
                    }
                    @media(max-width:500px){                            
                        font-size: 13px;
                        margin-bottom: 20px;
                    }
                }
                .explore{
                    color: black;
                    font-weight: 800;
                    border-radius: 5px;
                    padding: 10px 30px;
                    text-decoration: none;
                    background-color: white;
                    font-family: 'Jura', sans-serif;               
                    box-shadow: 2px 2px 0 1px black;    
                    @media(max-width:500px){                            
                        font-size: 14px;
                    }
                }
            }
            .person-card{
                right: 0;
                top: -20px;
                padding: 40px 60px;
                cursor: pointer;
                position: absolute;
                border-radius: 5px;
                background-color: white;
                border: 1px solid #E1053F;
                box-shadow: -6px 6px 0 0 black;
                @media(max-width:1400px){                            
                    padding: 30px 40px;
                    top: 0px;
                }
                @media(max-width:1250px){                            
                    padding: 30px;                    
                }
                @media(max-width:1100px){                            
                    top: -10px;                    
                }
                @media(max-width:900px){                                                          
                    padding: 20px;
                    right: -10px;
                }
                @media(max-width:600px){                                                          
                    top: 0;
                    right: 0;
                    width: 100%;
                    padding: 30px;
                    margin-top: 30px;
                    position: relative;
                }
                @media(max-width:400px){                                                          
                    padding: 20px;                    
                }
                .person{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .person-icon{
                        width: 70px;
                        height: 70px;
                        overflow: hidden;
                        border-radius: 50%;      
                        @media(max-width:1250px){                            
                            width: 60px;
                            height: 60px;                 
                        } 
                        @media(max-width:900px){                            
                            width: 50px;
                            height: 50px;                 
                        }    
                        @media(max-width:600px){                            
                            width: 60px;
                            height: 60px;                 
                        }               
                    }
                    .name{
                        margin: 0;
                        padding: 0;
                        font-weight: 700;
                        text-align: left;
                        font-family: 'Jura', sans-serif;
                        @media(max-width:1500px){                            
                            font-size: 23px;
                        }
                        @media(max-width:1250px){                            
                            font-size: 21px;
                        }
                        @media(max-width:1100px){                            
                            font-size: 18px;
                        }
                        @media(max-width:900px){                            
                            font-size: 16px;
                        }
                        @media(max-width:600px){                            
                            font-size: 20px;
                        }
                        p{
                            margin: 0;
                            padding: 0;
                            opacity: 0.5;
                            font-size: 14px;
                            font-weight: 400;
                            font-family: 'Jura', sans-serif;
                            @media(max-width:1250px){                            
                                font-size: 13px;
                            }
                            @media(max-width:1100px){                            
                                font-size: 12px;
                            }
                            @media(max-width:900px){                            
                                font-size: 11px;
                            }
                        }
                    }
                }
                .text{
                    margin-top: 20px;
                    font-weight: 600;
                    font-family: 'Jura', sans-serif;
                    @media(max-width:1250px){                            
                        font-size: 15px;
                        margin-top: 10px;
                    }
                    @media(max-width:1100px){                            
                        font-size: 14px;
                    }
                    @media(max-width:900px){                            
                        font-size: 13px;
                    }
                    @media(max-width:600px){                            
                        font-size: 14px;
                    }
                }
                .location{
                    font-size: 13px;
                    margin-top: 20px;
                    font-weight: 600;
                    font-family: 'Jura', sans-serif;
                    @media(max-width:1250px){                            
                        margin-top: 10px;
                    }
                    @media(max-width:1100px){                            
                        font-size: 11px;
                    }
                    @media(max-width:900px){                            
                        font-size: 10px;
                    }
                    @media(max-width:600px){                            
                        font-size: 12px;
                    }
                    .icon{
                        font-size: 32px;
                        @media(max-width:1100px){                            
                            font-size: 27px;
                        }
                        @media(max-width:900px){                            
                            font-size: 24px;
                        }
                    }
                }
                .youtube{
                    width: 100%;
                    height: 250px;
                    margin-top: 30px;
                    overflow: hidden;
                    border-radius: 6px;
                    position: relative;
                    @media(max-width:1400px){                            
                        height: 200px;
                    }
                    @media(max-width:1250px){                            
                        margin-top: 20px;
                    }
                    @media(max-width:1100px){                            
                        height: 160px;
                        margin-top: 10px;
                    }
                    @media(max-width:900px){                            
                        height: 130px;                        
                    }
                    @media(max-width:600px){                                                    
                        height: 200px;
                    }
                    @media(max-width:420px){                                                    
                        height: 150px;
                    }
                    iframe{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
        }
    }
    .tape, .tape1{        
        z-index: 3;
        width: 300%;
        bottom: 100px;
        display: flex;
        color: white;
        padding: 10px 0;
        font-size: 22px;
        position: absolute;
        align-items: center;
        transform: rotate(5deg);
        background-color: transparent;
        justify-content: space-between;
        @media(max-width:1420px){                                                    
            width: 350%;
            padding: 7px 0;
        }
        @media(max-width:1050px){                                                    
            width: 430%;
            padding: 5px 0;
            bottom: 120px;
        }
        @media (max-width:800px){
            bottom: 70px;
        }
        @media(max-width:600px){ 
            width: 700%;
            padding: 3px 0;
            font-size: 18px;
            transform: rotate(7deg);                                                   
        }
        @media (max-width:420px){
            bottom: 40px;
        }
        @media(max-width:400px){                                                    
            padding: 0;        
            width: 850%;   
        }
        p{
            margin: 0;
            padding: 0;
            font-size: 14px;
            @media(max-width:500px){                                                    
                font-size: 12px;
            }
            @media(max-width:400px){                                                    
                font-size: 11px;
            }
        }
    }
    .tape1{
        z-index: 1;
        transform: rotate(-5deg);
        @media(max-width:600px){                                                                            
            transform: rotate(-7deg);
        }
    }
    .crimson, .grey{
        z-index: 2;
        width: 110%;
        bottom: 100px;
        display: flex;
        padding: 10px 0;
        font-size: 22px;
        color: transparent;
        position: absolute;
        align-items: center;
        transform: rotate(5deg);
        background-color: #E1053F;
        justify-content: space-between;
        @media(max-width:1420px){                                                    
            padding: 7px 0;
        }
        @media(max-width:1050px){                                                    
            padding: 5px 0;
            bottom: 120px;
        }
        @media (max-width:800px){
            bottom: 70px;
        }
        @media(max-width:600px){                                                                
            padding: 3px 0;
            font-size: 18px;
            transform: rotate(7deg);
        }
        @media (max-width:420px){
            bottom: 40px;
        }
        @media(max-width:400px){                                                                
            padding: 0;        
        }
        p{
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: transparent;
            @media(max-width:500px){                                                    
                font-size: 12px;
            }
            @media(max-width:400px){                                                    
                font-size: 11px;
            }
        }
    }
    .grey{
        z-index: 0;
        transform: rotate(-5deg);
        background-color: #8C8C8C;
        @media(max-width:600px){                                                                            
            transform: rotate(-7deg);
        }
    }
}