.SuspenseLoader{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 100000001;
    align-items: center;
    justify-content: center;
    background-color: white;
    svg{
        width: 20vw;
        position: absolute;
        @media (max-width:500px) {
            width: 40vw;
        }
        .load-path1{
            stroke: white;
            fill: transparent;
            stroke-width: 2px;
            stroke-dasharray: 55;                
            animation: path 2s infinite linear;
        }
        .load-path2{
            stroke: white;
            fill: transparent;
            stroke-width: 2px;
            stroke-dasharray: 55;                       
            animation: path 2s infinite linear;
        }
        .load-path3{
            stroke: #060606;
            fill: transparent;
            stroke-width: 3px;
        }
        @keyframes path {
            0%{
                stroke-dashoffset: 110;
            }
            100%{
                stroke-dashoffset: 0;
            }
        }
    }
    .loader{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        animation-duration: 2s;
        animation-name: loader1;
        border: solid 10px transparent;
        border-top-color: #B50A3F;
        border-left-color: #B50A3F;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
    @keyframes loader1 {
        100%{
            transform: rotate(360deg);
        }
    }

    .circle{
        width: 30vw;
        height: 30vw;
        border-radius: 50%;
        background-color: #ccc;
        border: solid 0.5vw white;
        box-shadow: 0 0 0 0.5vw #B50A3F;
        @media (max-width:500px) {
            width: 50vw;
            height: 50vw;
            border: solid 1vw white;
            box-shadow: 0 0 0 1vw #B50A3F;
        }
        .wave{
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            border-radius: 50%;
            background-color: #B50A3F;
            box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
            &::before, &::after{
                top: 0;
                left: 50%;
                content: "";
                width: 200%;
                height: 200%;
                position: absolute;
                background-color: white;
                transform: translate(-50%, -75%);
            }
            &::before{
                border-radius: 45%;
                animation: wave 5s forwards linear;
                background-color: rgba(255, 255, 255, 1);
            }
            &::after{
                border-radius: 40%;
                animation: wave 5s forwards linear;
                background-color: rgba(255, 255, 255, 0.5);
            }
            .number{
                top: 0;
                left: 0;
                z-index: 5;
                width: 100%;
                height: 100%;
                display: flex;
                color: white;
                font-size: 5vw;
                position: absolute;
                align-items: center;
                justify-content: center;
                @media (max-width:500px){
                    font-size: 10vw;
                }
            }
            @keyframes wave {
                0%{
                    top: 0;
                    transform: translate(-50%, -75%) rotate(0deg);
                }
                100%{
                    top: -50%;
                    transform: translate(-50%, -75%) rotate(360deg);
                }
            }
        }
    }
}