.About {
  margin: 0;
  padding: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: sticky;
  padding-top: 150px;
  background-color: white;
  @media (max-width: 1000px) {
    padding-top: 110px;
  }
  @media (max-width: 500px) {
    padding-top: 100px;
  }
  .links {
    display: flex;
    margin-left: 10vw;
    @media (max-width: 500px) {
      margin-left: 5vw;
    }
    a {
      color: black;
      text-decoration: none;
      &:nth-child(1) {
        opacity: 0.5;
      }
      &:nth-child(2) {
        margin-left: 7px;
      }
      @media (max-width: 800px) {
        font-size: 15px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
  .wrapper {
    @media (max-width: 1000px) {
      padding-top: 30px;
    }
    @media (max-width: 500px) {
      padding-top: 20px;
      padding-bottom: 30px;
    }
    .landing {
      padding: 3vw 5vw;
      background-color: #fdeaea;
      .title, .filliar {
        color: black;
        font-size: 55px;
        font-weight: 600;
        text-align: left;
        font-family: "Space Grotesk", sans-serif;
        @media (max-width: 1200px) {
          font-size: 50px;
        }
        @media (max-width: 1000px) {
          font-size: 40px;
        }
        @media (max-width: 700px) {
          font-size: 35px;
        }
        @media (max-width: 600px) {
          font-size: 30px;
        }
        @media (max-width: 500px) {
          font-size: 25px;
        }
        @media (max-width: 390px) {
          font-size: 22px;
        }
        &::first-letter {
          text-transform: uppercase;
        }
      }
      .text {
        margin-top: 2vw;
        font-size: 18px;
        line-height: 28px;
        font-family: "Space Grotesk", sans-serif;
        @media (max-width: 1200px) {
          font-size: 16px;
        }
        @media (max-width: 900px) {
          font-size: 15px;
          line-height: 25px;
        }
        @media (max-width: 500px) {
          font-size: 14px;
          line-height: 23px;
        }
      }
      .fil {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .img {
        max-width: 100%;
        min-width: 100%;
        margin-top: 1.5vw;
        border-radius: 1.1vw;
        @media (max-width: 500px) {
          border-radius: 2vw;
        }
      }
      .person-card {
        display: flex;
        margin-top: 3vw;
        padding: 1.5vw 2vw;
        border-radius: 1vw;
        background-color: white;
        justify-content: space-between;
        @media (max-width: 500px) {
          border-radius: 2vw;
        }
        .col-6 {
          @media (max-width: 500px) {
            width: 60%;
          }
          .header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .person-icon {
              width: 7vw;
              height: 7vw;
              border-radius: 50%;
              @media (max-width: 900px) {
                width: 8vw;
                height: 8vw;
              }
              @media (max-width: 500px) {
                width: 9vw;
                height: 9vw;
              }
            }
            .name {
              h3 {
                font-weight: 600;
                font-family: "Jura", sans-serif;
                @media (max-width: 1350px) {
                  font-size: 1.8vw;
                }
                @media (max-width: 900px) {
                  font-size: 2.3vw;
                }
                @media (max-width: 500px) {
                  font-size: 3.3vw;
                  margin-bottom: 2px;
                }
              }
              p {
                margin: 0;
                opacity: 0.7;
                font-family: "Jura", sans-serif;
                @media (max-width: 1350px) {
                  font-size: 1vw;
                }
                @media (max-width: 900px) {
                  font-size: 1.5vw;
                }
                @media (max-width: 500px) {
                  font-size: 2.5vw;
                }
              }
            }
          }
          .desc {
            margin-top: 2vw;
            font-weight: 600;
            font-family: "Jura", sans-serif;
            @media (max-width: 1200px) {
              font-size: 1.3vw;
            }
            @media (max-width: 900px) {
              font-size: 1.7vw;
            }
            @media (max-width: 500px) {
              font-size: 2.7vw;
            }
          }
        }
        .location {
          padding-top: 2vw;
          @media (max-width: 500px) {
            padding-top: 1vw;
          }
          .marker {
            display: flex;
            align-items: center;
            justify-content: start;
            p {
              margin: 0;
              @media (max-width: 1350px) {
                font-size: 1.2vw;
              }
              @media (max-width: 500px) {
                font-size: 2.2vw;
              }
            }
            .icon {
              font-size: 40px;
              @media (max-width: 1350px) {
                font-size: 3.2vw;
              }
              @media (max-width: 500px) {
                font-size: 4vw;
              }
            }
          }
          img {
            margin-top: 1vw;
            max-width: 100%;
            min-width: 100%;
            margin-bottom: -8vw;
          }
        }
      }
      .video-card {
        margin-top: 5vw;
        padding: 3vw 2vw;
        margin-bottom: 2vw;
        border-radius: 1vw;
        background-color: white;
        box-shadow: 7px 7px 0 0 black;
        @media (max-width: 500px) {
          padding: 3vw;
          border-radius: 2vw;
        }
        h3 {
          font-size: 30px;
          font-weight: 600;
          font-family: "Space Grotesk", sans-serif;
          @media (max-width: 1200px) {
            font-size: 27px;
          }
          @media (max-width: 800px) {
            font-size: 25px;
          }
          @media (max-width: 500px) {
            font-size: 20px;
          }
        }
        p {
          margin-top: 1vw;
          font-size: 18px;
          font-family: "Space Grotesk", sans-serif;
          @media (max-width: 1200px) {
            font-size: 16px;
          }
          @media (max-width: 800px) {
            font-size: 15px;
          }
          @media (max-width: 500px) {
            font-size: 14px;
          }
        }
        iframe {
          height: 30vw;
          margin-top: 1vw;
          max-width: 100%;
          min-width: 100%;
          border-radius: 1vw;
          @media (max-width: 500px) {
            height: 50vw;
          }
        }
      }
    }
  }
}
