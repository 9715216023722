.Partners{
    margin: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 100px 0;
    overflow: hidden;
    position: sticky;
    background-color: white;
    @media(max-width:1300px){                            
        padding: 50px 0;
    }
    @media(max-width:800px){                            
        padding: 30px 0;
    }
    @media(max-width:600px){                            
        padding: 10px 0;
    }
    @media(max-width:600px){                            
        padding: 10px 20px;
    }
    .wrapper{
        .title{
            color: black;
            font-weight: 700;
            text-align: center;
            font-family: 'Jura', sans-serif;
            @media(max-width:1100px){                            
                font-size: 35px;
            }
            @media(max-width:800px){                            
                font-size: 30px;
            }
            @media(max-width:500px){                            
                font-size: 25px;
            }
        }
        .imgs{
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            justify-content: center;
            @media(max-width:500px){                            
                display: grid;
                margin-top: 20px;
                grid-template-columns: repeat(2, 2fr);
            }
            .partner{
                padding: 20px;
                @media(max-width:1000px){                            
                    padding: 10px;
                }
                @media(max-width:600px){                            
                    padding: 5px;
                }
                @media(max-width:500px){                            
                    width: 100%;
                }
                .body{
                    height: 7vw;
                    display: flex;
                    cursor: pointer;
                    overflow: hidden;
                    padding: 20px 50px;
                    align-items: center;
                    border-radius: 56px;
                    justify-content: center;
                    background-color: #E1053F;
                    @media(max-width:1400px){                            
                        padding: 10px 40px;
                    }
                    @media(max-width:1000px){                            
                        height: 8vw;
                        padding: 10px 30px;
                    }
                    @media(max-width:800px){                            
                        padding: 5px 20px;
                    }
                    @media(max-width:600px){
                        height: 9vw;                            
                        padding: 5px 15px;
                    }
                    @media(max-width:500px){
                        height: 15vw;                            
                        padding: 5px 35px;
                    }
                    @media(max-width:400px){                                                   
                        padding: 5px 30px;
                    }
                    &:hover{
                        img{
                            transform: scale(1.2);
                        }
                    }
                    img{                    
                        max-width: 100%;
                        max-height: 5vw;
                        transition: 0.3s;
                        @media(max-width:1000px){                            
                            max-height: 6vw;
                        }
                        @media(max-width:600px){                            
                            max-height: 7vw;
                        }
                        @media(max-width:500px){                            
                            max-height: 13vw;
                        }
                    }
                }
            }
        }
    }
}