.LastNews{
    margin: 0;
    padding: 0;
    z-index: 3;
    widows: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;
    .wrapper{
        padding-top: 7vw;
        padding-bottom: 7vw;
        .title{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;            
            .col-8, .col-2{
                display: flex;
                padding: 0 10px;
                justify-content: center;
                @media(max-width:1100px){
                    padding: 0 3px;                                                           
                }
            }  
            .col-8{
                @media(max-width:767px){
                    width: 100%;                                                          
                }
            } 
            .col-2{
                @media(max-width:767px){
                    width: 50%; 
                    margin-top: 25px;   
                    display: flex;                                                      
                    align-items: center;
                    justify-content: end;
                }
            }         
            .next{
                display: block;
                padding-left: 0;
                @media(max-width:767px){
                    display: none;                                                     
                }
                p{
                    opacity: 0.8;
                    color: white;
                    font-size: 13px;
                    text-align: left;
                    font-weight: 400;
                    font-family: 'Space Grotesk', sans-serif;
                    @media(max-width:1100px){
                        font-size: 12px;                                                       
                    }
                    @media(max-width:900px){
                        font-size: 10px;                                                       
                    }
                    @media(max-width:767px){
                        font-size: 14px;                                                       
                    }
                    @media(max-width:500px){
                        font-size: 12px;                                                       
                    }
                }
                .icons{
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    .left, .right{
                        width: 2.3vw;
                        height: 2.3vw;
                        padding: 3px;
                        opacity: 0.4;
                        display: flex;
                        color: white;
                        cursor: pointer;
                        margin-right: 15px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        border: solid 2px white;
                        &:hover{
                            opacity: 1;
                        }
                        @media(max-width:900px){
                            width: 2.7vw;
                            height: 2.7vw;                                                      
                        }
                        @media(max-width:767px){
                            width: 3.3vw;
                            height: 3.3vw;                                                      
                        }
                        @media(max-width:500px){
                            width: 4.2vw;
                            height: 4.2vw;  
                            border: solid 1px white;                                                    
                        }
                        @media(max-width:400px){
                            width: 5.2vw;
                            height: 5.2vw;                                                      
                        }
                    }
                    .opacity{
                        opacity: 1;
                    }
                }
            }
            .res{
                display: block;
                padding-left: 10px;
                @media(min-width:767px){
                    display: none;                                                     
                }
            }
            h1{
                margin: 0;
                padding: 0;
                color: white;
                text-align: left;
                font-size: 2.2vw;
                font-weight: 600;
                font-family: 'Space Grotesk', sans-serif;
                @media(max-width:900px){
                    font-size: 2.5vw;                                                       
                }
                @media(max-width:767px){
                    font-size: 3vw;                                                       
                }
                @media(max-width:600px){
                    font-size: 3.5vw;                                                       
                }
                @media(max-width:500px){
                    font-size: 4vw;                                                       
                }
            }
            .date{
                color: white;
                padding: 8px 25px;
                border-radius: 16px;               
                background-color: #e1054096;
                @media(max-width:900px){
                    font-size: 12px;   
                    padding: 6px 20px;
                    border-radius: 12px;                                                       
                }
            }
        }
        .black-title{
            h1, p, .date{
                color: black;
            }
            .next{
                p{
                    color: black;
                }
                .icons{
                    .left, .right{
                        color: black;
                        border-color: black;
                    }
                }
            }
        }
        .carousel{
            display: flex;
            flex-wrap: wrap;
            margin-top: 4vw;
            justify-content: space-between;
            @media(max-width:767px){
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }
            .col-4{
                padding: 10px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                @media(max-width:1000px){
                    padding: 5px;
                }
                @media(max-width:767px){
                    width: 100%;
                }
            }
            .body{
                width: 100%;
                height: 48%;
                padding: 30px;
                display: flex;
                align-items: center;
                border-radius: 18px;
                flex-direction: column;
                justify-content: space-between;
                background-color: #fdeaea2e;
                @media(max-width:1300px){
                    padding: 20px;   
                    border-radius: 16px;                       
                }
                @media(max-width:1000px){
                    padding: 15px;   
                    border-radius: 13px;                       
                } 
                @media(max-width:500px){
                    padding: 10px;   
                    border-radius: 8px;                       
                }                
                &:hover{
                    background-color: #e1054088;
                }
                .name{
                    color: white;
                    font-size: 1.2vw;
                    font-weight: 600;
                    font-family: 'Space Grotesk', sans-serif;
                    @media(max-width:1000px){
                        font-size: 1.4vw;                     
                    }
                    @media(max-width:767px){
                        font-size: 2.1vw;                                            
                    }
                    @media(max-width:500px){
                        font-size: 2.6vw;                                            
                        line-height: 12px;
                        margin-bottom: 3px;
                    }
                    @media(max-width:400px){
                        font-size: 3.2vw;                                                            
                    }
                }
                .desc{
                    color: white;
                    font-size: 1vw;
                    font-weight: 300;
                    font-family: 'Space Grotesk', sans-serif;
                    @media(max-width:1000px){
                        font-size: 1.2vw; 
                        margin-bottom: 0;                    
                    }
                    @media(max-width:767px){
                        font-size: 1.8vw;                                            
                    }
                    @media(max-width:500px){
                        font-size: 2.4vw;                                            
                        line-height: 12px;
                    }
                    @media(max-width:400px){
                        font-size: 3vw;                                                            
                    }
                }
                img{
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 13vw;
                    min-height: 13vw;
                    margin-top: 20px;
                    border-radius: 9px;
                    @media(max-width:767px){
                        max-height: 20vw;
                        min-height: 20vw;
                    }
                    @media(max-width:500px){
                        max-height: 24vw;
                        min-height: 24vw;
                        margin-top: 10px;
                        border-radius: 6px;
                    }
                }
            }       
            .other-back{
                background-color: #1F1414;
                &:hover{
                    background-color: #9A304C;
                }
            }
            .middle:nth-child(1){
                margin-bottom: 20px;
                @media(max-width:1000px){
                    margin-bottom: 10px;
                }
                @media(max-width:767px){
                    height: 48.5%;
                }
            }
            .middle:nth-child(2){                
                @media(max-width:767px){
                    height: 48.5%;
                }
            }   
            .responsive{
                @media(max-width:767px){
                    width: 200%;
                }
                .body{
                    @media(max-width:767px){                        
                        display: flex;
                        align-items: center;
                        flex-direction:row;
                    }
                    .texts{
                        @media(max-width:767px){
                            padding-right: 10px;
                        }
                    }
                    img{
                        @media(max-width:767px){                            
                            margin-top: 0;
                            max-width: 45%;
                            min-width: 45%;                        
                        }
                    }
                }
            }      
        }        
    }
}