@font-face {
    font-family: Zona-Pro;
    src: url(../../../assets/fonts/ZONAPRO-REGULAR.TTF);
}
.Navbar{
    background-color: #1E1E1E;
    transition: 0.2s linear;
    position: fixed;
    width: 100vw;
    z-index: 20;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    font-family: Zona-Pro;
    .navbar{
        justify-content: space-between;
        transition: 0.2s linear;
        align-items: center;
        padding: 30px 7vw;
        display: flex;
        width: 100vw;
        height: 100%;
        margin: 0;  
        @media(max-width:1400px){
            padding: 25px 5vw;
        } 
        @media(max-width:1050px){
            padding: 20px 3vw;
        } 
        @media(max-width:767px){
            padding: 20px 7vw;
        }     
        .logo{
            width: 21vw;
            @media(max-width:1300px){
                width: 19vw;  
            }
            @media(max-width:600px){
                width: 35vw;  
            }
            @media(max-width:450px){
                width: 45vw;  
            }
            @media(max-width:390px){
                width: 50vw;  
            }
            img{
                width: 100%;
            }
        }
        .navbar-nav{
            justify-content: space-between;
            transition: 0.4s linear;
            align-items: center;
            padding: 0 100px;
            display: flex;
            width: 100%;
            margin: 0;
            @media(max-width:1450px){
                padding: 0 50px;   
            }
            @media(max-width:1150px){
                padding: 0 20px;   
            }
            @media(max-width:950px){
                padding: 0 10px;   
            }
            @media(max-width:767px){
                background-color: black;
                flex-direction: column;
                position: absolute; 
                padding: 100px 0;
                padding-bottom: 200px;
                height: 100vh;
                right: -100vw;
                width: 100vw;
                top: 0;
            }
            .nav-item{
                padding: 0;
                margin: 0;
                .nav-link{
                    margin: 0;
                    color: white;
                    border-radius: 5px;
                    @media(max-width:1300px){
                        font-size: 14px;
                    }
                    @media(max-width:1050px){
                        font-size: 13px;
                    }
                    @media(max-width:950px){
                        font-size: 12px;
                        padding: 0 3px;
                    }
                    @media(max-width:767px){
                        font-size: 18px; 
                    }
                    @media(max-width:500px){
                        font-size: 16px; 
                    }
                    &:hover{
                        background-color: white;
                        color: black;
                    }
                }
                .language{
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    display: flex;
                    input[type="checkbox"]{
                        display: none;
                    }
                    label{                        
                        backdrop-filter: blur(5px);
                        background-color: rgba(255, 255, 255, 0.19);
                        border-top: solid 1px rgba(255, 255, 255, 0.5);                        
                        border-radius: 3px;
                        transition: 0.2s;
                        padding: 2px 7px;
                        cursor: pointer;
                        color: white;
                        &:hover{
                            background-color: white;
                            color: black;
                            .fa-angle-down{
                                color: black;
                            }
                        }
                        @media(max-width:1300px){
                            font-size: 14px;
                        }
                        @media(max-width:1050px){
                            font-size: 13px;
                        }
                        @media(max-width:950px){
                            font-size: 12px;
                        }
                        @media(max-width:767px){
                            font-size: 18px; 
                            border: solid 1px white;
                        }
                        @media(max-width:500px){
                            font-size: 16px; 
                        }
                        .fa-angle-down{
                            color: white;
                        }
                    }
                    .language-menu{
                        background-color: black;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        border-radius: 5px;
                        position: absolute;
                        overflow: hidden;
                        display: flex;
                        padding: 0;
                        margin: 0;
                        top: 30px;
                        @media(max-width:767px){
                            margin-top: 10px;                            
                            border: solid 1px white;
                        }                        
                        li{
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            list-style: none;
                            position: sticky;
                            display: flex;
                            width: 100%;
                            z-index: 131;
                            padding: 0;
                            margin: 0;
                            .lang-link{
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                padding: 5px 20px;
                                cursor: pointer;
                                color: white;
                                display: flex;
                                width: 100%;
                                margin: 0;
                                &:hover{
                                    background-color: white;
                                    color: black;
                                }
                                @media(max-width:1300px){
                                    font-size: 14px;
                                }
                                @media(max-width:1050px){
                                    font-size: 13px;
                                }
                                @media(max-width:767px){
                                    font-size: 18px;                                     
                                }
                                @media(max-width:500px){
                                    font-size: 16px; 
                                }
                            }
                        }
                    }
                }
            }
            .nav-tel{
                @media(min-width:767.1px){
                    display: none;
                }
            }
            .close-icon{
                position: absolute;
                font-size: 30px;
                cursor: pointer;
                color: white;
                right: 50px;
                top: 30px;
                @media(min-width:767.1px){
                    display: none;
                }
            }
        }
        .open{
            right: 0;
            transition: 0.4s linear;
        }
        .tel-number{
            background-color: #B50A3F;
            border-radius: 10.7607px;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 17px 22px;
            display: flex;
            @media(max-width:1300px){
                padding: 13px 20px;
                border-radius: 9px;
            }
            @media(max-width:1300px){
                padding: 10px 15px;
                border-radius: 8px;
            }
            @media(max-width:767px){
                display: none;
            }
            .tel{
                line-height: 13px;
                font-weight: 500;
                font-size: 16px;
                color: white;
                padding: 0;
                margin: 0;
                @media(max-width:1300px){
                    font-size: 14px;
                }
                @media(max-width:1050px){
                    line-height: 11px;
                    font-size: 13px;
                }
                @media(max-width:950px){
                    font-size: 12px;
                }
            }
            .aloqa{
                line-height: 13px;
                font-weight: 300;
                font-size: 12px;
                color: white;
                padding: 0;
                margin: 0;
                @media(max-width:1300px){
                    font-size: 11px;
                }
                @media(max-width:1050px){
                    line-height: 11px;
                    font-size: 10px;
                }
                @media(max-width:950px){
                    font-size: 9px;
                }
            }
        }
        .open-icon{
            color: white;
            @media(min-width:767.1px){
                display: none;
            }
        }
    }
}

.black{
    background-color: #1e1e1eca;
    transition: 0.2s linear;
    .navbar{
        padding: 8px 7vw;
        transition: 0.2s linear;
        @media(max-width:1400px){
            padding: 7px 5vw;
        } 
        @media(max-width:1050px){
            padding: 5px 3vw;
        }
        @media(max-width:767px){
            padding: 5px 7vw;
        }
    }
}