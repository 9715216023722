.News{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    padding-top: 150px;
    background-color: white;
    @media(max-width:1000px){
        padding-top: 110px;
    }
    @media(max-width:500px){
        padding-top: 100px;
    }
    .links{
        display: flex;
        margin-left: 10vw;
        @media(max-width:500px){
            margin-left: 5vw;
        }
        a{
            color: black;
            text-decoration: none;
            &:nth-child(1){
                opacity: 0.5;
            }
            &:nth-child(2){
                margin-left: 7px;
            }
            @media(max-width:800px){
                font-size: 15px;
            }
            @media(max-width:500px){
                font-size: 14px;
            }
        }
    }
    .news-page{
        display: flex;
        padding-bottom: 0;
        align-items: flex-start;
        justify-content: center;
        @media(max-width:1000px){
            padding-top: 30px;
        }
        @media(max-width:500px){
            padding-top: 20px;
        }
        .cards{
            @media (max-width:800px) {
                width: 100%;
            }
            .date{
                font-size: 18px;                
                @media (max-width:1200px) {
                    font-size: 16px;
                }
                @media (max-width:800px) {
                    font-size: 15px;
                }
                @media (max-width:500px) {
                    font-size: 14px;
                }
            }
            .title{
                color: black;
                font-size: 50px;
                font-weight: 600;
                text-align: left;  
                margin-top: 1vw;
                font-family: 'Space Grotesk', sans-serif;    
                @media(max-width:1200px){
                    font-size: 40px;
                }
                @media(max-width:1000px){                            
                    font-size: 35px;
                }
                @media(max-width:600px){                            
                    font-size: 30px;
                }
                @media(max-width:500px){                            
                    font-size: 22px;
                }
                @media(max-width:390px){                            
                    font-size: 20px;
                }
            }
            .desc{
                font-size: 18px;
                margin-top: 1.5vw;
                @media (max-width:1200px) {
                    font-size: 16px;
                }
                @media (max-width:800px) {
                    font-size: 15px;
                }
                @media (max-width:500px) {
                    font-size: 14px;
                }
            }
            img{
                max-width: 100%;
                min-width: 100%;
                margin-top: 1.5vw;
                border-radius: 1.1vw;
            }
            iframe{
                height: 30vw;
                margin-top: 3vw;
                max-width: 100%;
                min-width: 100%;
                border-radius: 1vw;
                @media (max-width:500px) {
                    height: 50vw;
                }
            }   
            .bottom{
                display: flex;
                margin-top: 4vw;
                justify-content: space-between;
                .author, .share, .view{
                    color: black;
                    font-size: 18px;                
                    text-decoration: none;
                    @media (max-width:1200px) {
                        font-size: 16px;
                    }
                    @media (max-width:800px) {
                        font-size: 15px;
                    }
                    @media (max-width:500px) {
                        font-size: 14px;
                    }
                    @media (max-width:450px) {
                        font-size: 12px;
                    }
                    @media (max-width:400px) {
                        font-size: 11px;
                    }
                }
                .share-icon, .eye-icon{
                    @media (max-width:500px) {
                        font-size: 18px;
                    }
                    @media (max-width:450px) {
                        font-size: 16px;
                    }
                    @media (max-width:400px) {
                        font-size: 14px;
                    }
                }                
            }         
        }
    }
}