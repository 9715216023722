.NotFound{
    background-color: #9afffc;
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    padding: 0;
    margin: 0;
    .wrapper{
        justify-content: center;
        flex-direction: column;
        align-items: center;
        display: flex;
        .number{
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-duration: 10s;
            justify-content: center;
            animation-name: number;
            align-items: center;
            position: absolute;
            top: -15vw;
            display: flex;
            padding: 0;
            margin: 0;
            .not-found1, .not-found2{
                background: -webkit-linear-gradient(90deg, #1cc4c4, #2415c3);
                -webkit-text-fill-color: transparent;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                -webkit-background-clip: text;
                animation-duration: 10s;
                position: absolute;
                font-size: 15vw;
                z-index: 0;
                margin: 0; 
                @media(max-width:900px){
                    font-size: 20vw;
                } 
                @media(max-width:500px){
                    font-size: 25vw;
                }  
            }
            .not-found1{
                left: 1vw;    
                animation-name: found1;                            
            }
            .not-found2{
                right: 1vw;  
                animation-name: found2;                
            }
            .radius{
                background: linear-gradient(45deg, #1cc4c4, #3120E0);
                border-radius: 50%;
                position: sticky;
                height: 15vw;
                width: 15vw;
                z-index: 1;
                @media(max-width:900px){
                    height: 20vw;
                    width: 20vw;
                }
                @media(max-width:500px){
                    height: 25vw;
                    width: 25vw;
                }
            }
        }
        .text{
            background: -webkit-linear-gradient(90deg, #3120E0, #21E1E1);
            -webkit-text-fill-color: transparent;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            -webkit-background-clip: text;
            animation-duration: 10s;
            animation-name: text;
            position: absolute;
            font-size: 2.2vw;
            top: -7.5vw;
            margin: 0;
            @media(max-width:900px){
                top: -7vw;
            }
            @media(max-width:500px){
                font-size: 2vw;  
            } 
        }
    }
    .circle-0{
        background: linear-gradient(135deg, rgb(151, 0, 151), rgb(250, 45, 86));
        border-radius: 50%;
        position: absolute;
        height: 25vw;
        width: 25vw;
        left: -5vw;
        top: -3vw;
    }
    .circle-1{
        background: linear-gradient(135deg, #A66CFF, #21E1E1);
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: circle1;
        animation-duration: 7s;
        border-radius: 50%;
        position: absolute;
        height: 20vw;
        width: 20vw;
        right: -5vw;
        top: -25vw;
    }
}

@keyframes number {
    10%{
        top: calc(50% - 13.75vw);
    }
    70%{
        top: calc(50% - 13.75vw);
    }
    80%{
        top: -15vw;
    }
}

@keyframes found1 {
    10%{
        left: 1vw;
    }
    17%{
        left: -10vw;
    }
    60%{
        left: -10vw;
    }
    67%{
        left: 1vw;
    }
    100%{
        left: 1vw;
    }
}

@keyframes found2 {
    10%{
        right: 1vw;
    }
    17%{
        right: -10vw;
    }
    60%{
        right: -10vw;
    }
    67%{
        right: 1vw;
    }
    100%{
        right: 1vw;
    }
}

@keyframes text {
    10%{
        top: calc(50% - 7.5vw);
        @media(max-width:900px){
            top: calc(50% - 7vw);
        }
    }
    17%{
        top: calc(50% + 3.25vw);
        font-size: 70px;
        @media(max-width:900px){
            top: calc(50% + 4vw);
        }
    }
    60%{
        top: calc(50% + 3.25vw);
        font-size: 70px;
        @media(max-width:900px){
            top: calc(50% + 4vw);
        }
    }
    67%{
        top: 50%;
        font-size: 40px;
        @media(max-width:900px){
            top: calc(50% + 4vw);
        }
    }
    80%{
        top: -5vw;
        @media(max-width:900px){
            top: -5.5vw;
        }
    }
}

@keyframes circle1 {
    15%{
        top: 75%;
        right: -5vw;
    }
    23%{
        top: 50%;
        right: -3vw;
    }
    25%{
        top: 50%;
        right: -2vw;
    }
    35%{
        top: 75%;
        right: 2vw;
    }
    55%{
        top: 75%;
        right: 25vw;
    }
    100%{
        top: 75%;
        right: 90vw;
        transform: rotate(-1080deg);
    }
}