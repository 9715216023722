.OneService{
    margin: 0;
    padding: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    padding-top: 150px;
    background-color: white;
    @media(max-width:1000px){
        padding-top: 110px;
    }
    @media(max-width:500px){
        padding-top: 100px;
    }
    .links{
        display: flex;
        margin-left: 10vw;
        @media(max-width:500px){
            margin-left: 5vw;
        }
        a{
            color: black;
            text-decoration: none;
            &:nth-child(1){
                opacity: 0.5;
            }
            &:nth-child(2){
                margin-left: 7px;
            }
            @media(max-width:800px){
                font-size: 15px;
            }
            @media(max-width:500px){
                font-size: 14px;
            }
        }
    }
    .wrapper{
        @media(max-width:1000px){
            padding-top: 30px;
        }
        @media(max-width:500px){
            padding-top: 20px;
            padding-bottom: 30px;
        }
        .title{
            color: black;
            font-size: 55px;
            font-weight: 600;
            text-align: left;  
            font-family: 'Space Grotesk', sans-serif; 
            @media(max-width:1200px){
                font-size: 50px;
            }   
            @media(max-width:1000px){
                font-size: 40px;
            }
            @media(max-width:700px){                            
                font-size: 35px;
            }
            @media(max-width:600px){                            
                font-size: 30px;
            }
            @media(max-width:500px){                            
                font-size: 25px;
            }
            @media(max-width:390px){                            
                font-size: 22px;
            }
            &::first-letter{
                text-transform: uppercase;
            }
        }  
        .text{
            margin-top: 2vw;
            font-size: 18px;
            line-height: 28px;
            font-family: 'Space Grotesk', sans-serif;  
            @media (max-width:1200px) {
                font-size: 16px;
            }
            @media (max-width:900px) {
                font-size: 15px;
                line-height: 25px;
            }
            @media (max-width:500px) {
                font-size: 14px;
                line-height: 23px;
            }
        }
        .img{
            max-width: 100%;
            min-width: 100%;
            margin-top: 1.5vw;
            border-radius: 1.5vw;            
            @media (max-width:500px) {
                border-radius: 2vw;
            }
        }
        .social{
            display: flex;
            margin-top: 2vw;
            align-items: center;
            justify-content: space-between;
            @media(max-width:1100px){
                margin-top: 4vw;
            }
            .support{
                border: none;
                outline: none;
                color: white;
                border-radius: 50px;
                padding: 0.8vw 1.6vw;
                background: #B50A3F;
                box-shadow: 0px 20px 50px -10px rgba(82, 5, 29, 0.5);
                @media (max-width:1100px) {
                    font-size: 14px;
                    padding: 1vw 2vw;
                    border-radius: 2vw;
                }
                @media (max-width:500px) {
                    font-size: 13px;
                    padding: 1.5vw 3.3vw;
                    border-radius: 3.5vw;
                }
            }
            .icons{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                a{
                    text-decoration: none;
                }
                .fa{
                    width: 3vw;
                    height: 3vw;
                    color: white;
                    display: flex;
                    font-size: 1.7vw;
                    margin-right: 1vw;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    background-color: #B50A3F;
                    @media (max-width:1100px) {
                        width: 4vw;
                        height: 4vw;
                        font-size: 2vw;
                    }
                    @media (max-width:500px) {
                        width: 7vw;
                        height: 7vw;
                        font-size: 4vw;
                        margin-right: 2vw;
                    }
                }
            }
        }
    }
}
.service-contact{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    .contact{
        width: 60%;
        display: flex;
        z-index: 1001;
        position: sticky;
        padding: 2vw 8vw;
        border-radius: 1.5vw;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: white;    
        @media (max-width:1000px) {
            width: 80%;
            padding: 4vw 8vw;
        }
        @media (max-width:600px) {
            width: 80%;
            border-radius: 3vw;
        }
        @media (max-width:420px) {
            padding: 4vw;
        }
        .close-icon{
            top: 20px;
            right: 20px;
            font-size: 35px;
            cursor: pointer;
            position: absolute;
            @media (max-width:600px) {
                top: 5px;
                right: 10px;
                font-size: 25px;
            }
        }
        .header{
            width: 100%;
            display: flex;
            margin-bottom: 1vw;
            align-items: center;
            justify-content: space-between;
            img{
                max-width: 13vw;
                min-width: 13vw;
                max-height: 13vw;
                min-height: 13vw;
                margin-right: 1vw;
                border-radius: 50%;
                @media (max-width:1000px) {
                    max-width: 17vw;
                    min-width: 17vw;
                    max-height: 17vw;
                    min-height: 17vw;
                }
                @media (max-width:600px) {
                    max-width: 23vw;
                    min-width: 23vw;
                    max-height: 23vw;
                    min-height: 23vw;
                }
            }
            .service-text{
                margin-left: 1vw;
                .name{
                    color: black;
                    font-size: 2.5vw;
                    font-weight: 400;
                    text-align: left;  
                    font-family: 'Space Grotesk', sans-serif;                 
                    &::first-letter{
                        text-transform: uppercase;
                    }
                    @media (max-width:1000px) {
                        font-size: 3.5vw;
                    }
                    @media (max-width:600px) {
                        font-size: 4.5vw;
                    }
                }  
                .desc{
                    font-size: 1vw;                    
                    font-family: 'Space Grotesk', sans-serif; 
                    @media (max-width:1000px) {
                        font-size: 2vw;                        
                    }
                    @media (max-width:600px) {
                        font-size: 2.5vw;                        
                    }       
                    @media (max-width:420px) {
                        margin: 0;
                        font-size: 3vw;
                    }           
                }
            }
        }
        .texts{
            width: 100%;
            padding: 1vw;
            .desc{
                color: black;
                font-size: 1vw;
                font-weight: 300;
                text-align: center;
                @media (max-width:1000px) {
                    font-size: 2vw;
                }
                @media (max-width:600px) {
                    font-size: 2.5vw;                        
                }  
                @media (max-width:420px) {
                    margin: 0;
                    font-size: 3vw;
                }       
            }
            .title{
                color: black;
                font-size: 2.5vw;
                text-align: center;  
                @media (max-width:1000px) {
                    font-size: 3.5vw;
                }
                @media (max-width:600px) {
                    font-size: 4.5vw;
                }  
            }
        }
        .form{
            padding: 1vw;
            @media(max-width:900px){
                padding: 15px;
            }
            @media(max-width:800px){
                padding: 10px;
            }
            @media(max-width:650px){
                width: 100%;
            }
            .body{
                padding: 1.1vw 2vw;
                border-radius: 2vw;
                border: solid 2px rgba(1, 1, 1, 0.386);
                @media(max-width:800px){
                    padding: 20px;
                    border-radius: 23px;
                }
                @media(max-width:500px){
                    border-radius: 19px;
                }
                .form-control{
                    border: none;
                    color: black;
                    padding: 0.7vw 0;
                    border-radius: 0;
                    box-shadow: none;
                    background-color: transparent;
                    border-bottom: solid 2px rgba(0, 0, 0, 0.386);
                    &::placeholder{
                        color: #8D97A9;
                    }
                    @media(max-width:800px){
                        font-size: 15px;
                    }
                }
                input::-webkit-inner-spin-button,
                input::-webkit-outer-spin-button{
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type=number]{
                    -moz-appearance: 0;
                }
                .number{
                    margin-top: 2vw;
                    @media(max-width:800px){
                        margin-top: 15px;
                    }
                }
                .red-line{
                    color: #E1053F;
                    border-bottom: solid 2px #E1053F;
                    &::placeholder{
                        color: #E1053F;
                    }
                }
                .send{
                    width: 100%;
                    border: none;
                    outline: none;
                    color: white;
                    padding: 13px 0;
                    margin-top: 2vw;
                    background-color: #E1053F;
                    @media(max-width:800px){
                        padding: 8px 0;
                        font-size: 15px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}