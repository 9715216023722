*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html{
  scroll-behavior: smooth;
}

body {
  background: url('./assets/images/back.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}