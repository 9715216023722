@font-face {
    font-family: Zona-Pro;
    src: url(../../../assets/fonts/ZONAPRO-REGULAR.TTF);
}

.Header{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: sticky;
    align-items: center;
    justify-content: center;
    font-family: Zona-Pro;
    .wrapper{
        padding: 0;
        .carousel{
            .page{
                width: 100vw;
                height: 100%;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                .image{
                    z-index: 0;
                    width: 100%;
                    height: 100vh;
                    position: sticky;
                    object-fit: cover;                     
                    filter: brightness(0.6); 
                }
                .texts{
                    top: 0;
                    left: 0;
                    z-index: 3;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    padding: 0 13vw;
                    position: absolute;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    @media(max-width:450px){
                        padding: 0 10vw;
                    }
                    
                    .text__blur {
                        backdrop-filter: blur(7px);
                        padding: 16px 8px;
                        .name{
                            width: 100%;
                            color: white;
                            font-size: 55px;
                            font-weight: 300;
                            text-align: left;
                            padding-right: 50%;
                            @media(max-width:1550px){
                                padding-right: 30%;
                            }
                            @media(max-width:1300px){
                                padding-right: 10%;
                            }
                            @media(max-width:1050px){
                                padding-right: 0;
                                font-size: 45px;
                            }
                            @media(max-width:700px){                            
                                font-size: 35px;
                            }
                            @media(max-width:600px){                            
                                font-size: 30px;
                            }
                            @media(max-width:500px){                            
                                font-size: 25px;
                            }
                            @media(max-width:390px){                            
                                font-size: 22px;
                            }
                        }
                        .desc{
                            width: 100%;
                            color: white;
                            font-size: 22px;
                            margin-top: 20px;
                            text-align: left;
                            font-weight: 400;
                            padding-right: 50%;
                            font-family: Zona-Pro;
                            @media(max-width:1550px){
                                padding-right: 30%;
                            }
                            @media(max-width:1300px){
                                padding-right: 10%;
                            }
                            @media(max-width:1050px){
                                padding-right: 0;
                            }
                            @media(max-width:600px){                            
                                font-size: 16px;
                                margin-top: 10px;
                            }
                            @media(max-width:390px){                            
                                font-size: 14px;
                            }
                        }
                        .link{
                            width: 100%;
                            margin-top: 30px;
                            @media(max-width:600px){                                                      
                                margin-top: 20px;
                            }
                            .explore{      
                                color: white;
                                font-size: 16px;
                                padding: 15px 25px;                  
                                border-radius: 24px;
                                text-decoration: none;
                                background-color: #B50A3F;
                                border: solid 1px #B50A3F;
                                font-family: Zona-Pro;
                                &:hover{
                                    background-color: #b50a40a1;
                                }
                                @media(max-width:600px){                            
                                    font-size: 14px;
                                    padding: 10px 20px; 
                                    margin-top: 20px;
                                }
                                @media(max-width:390px){                            
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
            width: 100vw;
            height: 100%; 
            .slick-dots{
                opacity: 0;                
            }
        }
    }
    .numbers{
        margin: 0;
        padding: 0;
        bottom: 7%;
        width: 100%;
        display: flex;
        text-align: center;
        position: absolute;
        align-items: flex-start;
        justify-content: center;    
        font-family: Zona-Pro;            
        @media (max-width:450px){
            bottom: 6%;
        }
        li{
            opacity: 1;
            width: 18px;
            height: 18px;
            display: flex;
            color: white;
            margin: 0 10px;
            // cursor: pointer;
            font-size: 18px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            @media (max-width:450px){
                width: 16px;
                height: 16px;
                font-size: 16px;
            }                        
        }
        .dot-active{
            background-color: white;
        }
    }
}
