.row{
  --bs-gutter-x: 0;
}

.wrapper{
  padding: 50px 15vw;
  height: 100%;
  width: 100%;
  @media(max-width:1000px){
    padding: 50px 13vw;
  }
  @media(max-width:700px){
    padding: 50px 10vw;
  }  
  @media(max-width:500px){
    padding: 50px 7vw;
  }
}

.contrast-0{
  background-color: transparent;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
}

.contrast{
  background-color: rgba(0, 0, 0, 0.501);
  position: fixed;
  height: 100%;
  z-index: 101;
  width: 100%;
  left: 0;
  top: 0;
}

.contrast-05{
  background-color: rgba(0, 0, 0, 0.268);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
}