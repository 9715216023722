.Loader{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    position: fixed;
    align-items: center;
    visibility: inherit;
    flex-direction: column;
    justify-content: center;
    transition: 0.4s linear;
    background-color: black;
    svg{
        width: 50vw;
        @media (max-width:500px) {
            width: 80vw;
        }
        .path1{
            stroke: white;
            fill: transparent;
            stroke-width: 0.5px;
            stroke-dasharray: 190;
            stroke-dashoffset: 190;
            animation: path1 3s forwards linear;
            animation-delay: 1s;
        }
        .path2{
            stroke: white;
            fill: transparent;
            stroke-width: 0.5px;
            stroke-dasharray: 310;
            stroke-dashoffset: 310;
            animation: path1 3s forwards linear;
            animation-delay: 1s;
        }
        @keyframes path1 {
            to{
                stroke-dashoffset: 0;
            }
        }
    }
    .logo{
        font-size: 10vw;
        font-weight: 400;
        position: absolute;
        width: 100%;
        left: 0;
        text-align: center;
    }
    
    .percent{
        right: 0;
        width: 0;
        bottom: 10%;
        height: 5px;
        position: absolute;
        animation-name: line;
        animation-duration: 4s;
        background-color: black;
        animation-timing-function: linear;
    }
    
    .out-door{
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        position: absolute;
        align-items: center;
        animation-name: door;
        justify-content: center;
        animation-duration: 4s;
        background-color: black;
        animation-timing-function: linear;
        .logo1{
            top: auto;
            left: 0;
            z-index: 2;
            width: 100%;
            color: white;
            font-size: 10vw;
            font-weight: 400;
            text-align: center;
            position: absolute;
            animation-name: logo;
            animation-duration: 4s;
            animation-timing-function: linear;
        }
        .percent1{
            right: 0;
            width: 0;
            bottom: 10%;
            height: 5px;
            position: absolute;
            animation-name: line1;
            animation-duration: 5s;
            background-color: white;
            animation-timing-function: linear;
        }
    }    
}
.no-load{
    opacity: 0;
    visibility: hidden;
    transition: 0.4s linear;
}

@keyframes door {
    100%{
        right: -100%;
    }
}

@keyframes logo {
    100%{
        left: -100%;        
    }
}

@keyframes line {
    100%{
        width: 100%;         
    }
}

@keyframes line1 {
    100%{
        width: 100%; 
        right: 100%;        
    }
}

