.Tradition{
    margin: 0;
    z-index: 2;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;
    background-color: white;
    margin-top: 1600px;
    @media (max-width:767px){
        margin-top: 0;
    }
    .wrapper{
        padding-top: 4vw;
        padding-bottom: 4vw;
        background-color: #b50a401b;
        .title{
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            .fact{
                color: white;                
                padding: 5px 20px;
                margin-right: 15px;
                border-radius: 14px;
                text-decoration: none;
                background-color: #ED0A6E;
                @media(max-width:600px){                            
                    font-size: 14px;
                    margin-right: 10px;
                }
                @media(max-width:500px){ 
                    font-size: 12px;                           
                    padding: 3px 13px;
                    border-radius: 9px;
                }
            }
            .trad{
                margin: 0;
                color: black;
                font-weight: 700;
                margin-left: 15px;
                text-align: center;
                font-family: 'Jura', sans-serif;
                @media(max-width:1100px){                            
                    font-size: 35px;
                }
                @media(max-width:800px){                            
                    font-size: 25px;
                }
                @media(max-width:600px){                            
                    font-size: 4vw;
                    margin-right: 10px;
                }
                @media(max-width:500px){                            
                    font-size: 4.5vw;
                    margin-right: 10px;
                }
            }
        }
        .carousel{
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;            
            margin-top: 4vw;            
            .carousel-page{
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                display: flex !important;
                @media(max-width:650px){                            
                    display: grid !important;
                    grid-template-columns: repeat(2, 2fr);
                }               
                .texts{
                    padding: 20px;  
                    @media(max-width:650px){
                        width: 100%;
                    }                
                    .name{
                        font-size: 2vw;
                        text-align: left;
                        font-weight: 700;
                        font-family: 'Jura', sans-serif;
                        @media (max-width:950px){
                            font-size: 2.4vw;
                        }
                        @media (max-width:650px){
                            font-size: 3.5vw;
                        }
                        @media (max-width:500px){
                            font-size: 4vw;
                        }
                    }
                    .desc{
                        margin: 0;
                        height: 8vw;
                        font-weight: 500;
                        margin-top: 20px;
                        font-size: 0.9vw;
                        overflow: hidden;
                        font-family: 'Jura', sans-serif;
                        @media (max-width:1250px){
                            height: 11.5vw;
                            font-size: 1.1vw;
                        }
                        @media (max-width:950px){
                            font-size: 1.3vw;
                        }
                        @media (max-width:650px){
                            height: 18.5vw;
                            font-size: 2.1vw;
                            margin-top: 10px;
                        }
                        @media (max-width:500px){
                            font-size: 2.5vw;
                        }
                        @media (max-width:400px){
                            height: 25vw;
                            margin-top: 0;
                            font-size: 2.8vw;
                        }
                    }
                    .number{
                        margin: 0;
                        padding: 0;
                        opacity: 0.2;
                        font-size: 15vw;
                        color: #E1053F;
                        font-family: 'Bebas Neue', cursive;
                        @media(max-width:650px){
                            font-size: 22vw;
                        }
                    }
                }
                .img1{                    
                    padding: 10px;      
                    @media(max-width:650px){
                        width: 100%;
                    }             
                    .body{
                        height: 33vw;
                        overflow: hidden;
                        position: relative;
                        border-radius: 12px;
                        @media(max-width:650px){
                            height: 53vw;
                            border-radius: 7px;
                        } 
                        &:hover{
                            img{
                                transform: scale(1.1);
                            }
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                            transition: 0.3s;
                            position: absolute;
                        }
                    }
                }
                .imgs{
                    display: flex;
                    padding: 0 10px;
                    flex-wrap: wrap;
                    align-items: flex-start !important;
                    @media(max-width:650px){
                        width: 200%;
                        display: flex;
                        padding: 10px;
                        flex-wrap: nowrap;
                    }
                    .body{
                        overflow: hidden;
                        position: relative;
                        border-radius: 12px;
                        height: calc(33vw/2 - 10px);
                        @media(max-width:650px){
                            border-radius: 7px;  
                            height: calc(57vw/2);                         
                            width: calc(50% - 10px); 
                        }
                        &:nth-child(1){
                            margin-bottom: 17.5px;
                            @media(max-width:650px){
                                margin-right: 20px;
                            }
                        }
                        &:hover{
                            img{
                                transform: scale(1.1);
                            }
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                            transition: 0.3s;
                            position: absolute;
                        }
                    }
                }
            }
            .slick-prev, .slick-next{
                opacity: 0;
            }
            .slick-dots{
                top: -70px;
                right: 55vw;
                opacity: 1;
                z-index: 3;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: rotate(90deg);
                @media (max-width:1500px){
                    right: 57vw;
                }
                @media (max-width:1200px){
                    right: 59vw;
                }
                @media (max-width:1000px){
                    right: 61vw;
                }
                @media (max-width:800px){
                    right: 66vw;
                }
                @media (max-width:650px){
                    right: 94vw;
                }
                @media (max-width:500px){
                    right: 102vw;
                }
                @media (max-width:400px){
                    right: 106vw;
                }
                li{       
                    padding: 0 10px;                         
                    button{                                      
                        &::before{
                            width: 11px;
                            height: 11px;
                            display: flex;
                            color: #B50A3F;
                            border-radius: 50%;
                            align-items: center;
                            justify-content: center;
                            background-color: #B50A3F;
                            @media (max-width:1000px){
                                width: 9px;
                                height: 9px;
                            }
                            @media (max-width:500px){
                                width: 8px;
                                height: 8px;
                            }
                        }
                    }
                }       
                .slick-active{
                    transition: 0.5s;
                    button{        
                        transition: 0.5s;            
                        &::before{
                            opacity: 1;
                            width: 30px;
                            height: 11px;
                            display: flex;
                            transition: 0.5s;
                            margin-left: -8px;
                            border-radius: 6px;
                            align-items: center;
                            justify-content: center;
                            background-color: #B50A3F;
                            @media (max-width:1000px){
                                width: 22px;
                                height: 8px;
                                border-radius: 4px;
                            }
                            @media (max-width:500px){
                                width: 20px;
                                height: 7px;
                                border-radius: 4px;
                            }
                        }
                    }
                }     
            }
        }
    }
}