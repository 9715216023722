.TextAnimation{
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
    @media(max-width:767px){
        height: 500px;                            
    }
    @media(max-width:500px){
        height: 400px;                            
    }
    @media(max-width:430px){
        height: 370px;                            
    }
    img{
        width: 40vw;
        @media(max-width:1100px){
            width: 50vw;
        }
        @media(max-width:767px){
            width: 60vw;
        }
        @media(max-width:500px){
            width: 70vw;
        }
        @media(max-width:430px){
            width: 80vw;
        }
    }
    h1{
        left: -50%;
        width: 200%;
        font-size: 7.5vw;
        text-align: center;
        position: absolute;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.165);
        @media(max-width:500px){
            font-size: 8vw;
        }
    }
    .top-text{
        top: 10%;
    }
    .bottom-text{
        bottom: 10%;
    }
}