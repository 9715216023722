.Services{
    // top: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    // height: 4000px;
    height: auto;
    overflow: hidden;
    position: sticky;
    @media(max-width:767px){                            
        height: auto;
        top: auto;
    }   
    .wrapper{
        width: 100%;
        height: 100%;
        // position: fixed;
        @media(max-width:767px){                            
            padding-bottom: 0;
            position: relative;
        } 
        .title{
            color: white;
            font-size: 55px;
            font-weight: 600;
            text-align: center;  
            font-family: 'Jura', sans-serif;      
            @media(max-width:1000px){
                font-size: 45px;
            }
            @media(max-width:700px){                            
                font-size: 35px;
            }
            @media(max-width:600px){                            
                font-size: 30px;
            }
            @media(max-width:500px){                            
                font-size: 25px;
            }
            @media(max-width:390px){                            
                font-size: 22px;
            }
        }
        .title-text{
            color: white;
            font-size: 20px;      
            margin-top: 20px;
            font-weight: 500;
            text-align: center;      
            font-family: 'Jura', sans-serif;
            @media(max-width:1000px){
                font-size: 18px;
            }
            @media(max-width:600px){                            
                font-size: 16px;
                margin-top: 10px;
            }
            @media(max-width:500px){                            
                font-size: 14px;
            }
            @media(max-width:390px){                            
                font-size: 13px;
            }
        }
        .services-card{
            z-index: 2;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 3vw;
            transition: 0.1s;
            position: sticky;
            perspective: 5000px;
            justify-content: space-between;
            .service{
                z-index: 2;     
                padding: 20px;
                transition: 0.1s;                 
                transform-style: preserve-3d;   
                @media(max-width:1000px){
                    padding: 10px;
                }
                @media(max-width:500px){
                    padding: 7px;
                }
                .back{
                    top: 20px;
                    left: 20px;
                    position: absolute;
                    border-radius: 15px;
                    width: calc(100% - 40px);
                    height: calc(100% - 40px);
                    transform: rotateY(180deg);
                    backface-visibility: hidden;
                    background-color: #d6d6d639; 
                    img{
                        width: 60%;
                        position: absolute;
                        @media(max-width:700px){
                            width: 80%;
                        } 
                    }                   
                }
                .body{
                    padding: 40px;
                    display: flex;
                    flex-wrap: wrap;
                    overflow: hidden;
                    align-items: center;
                    border-radius: 15px;
                    text-decoration: none;
                    justify-content: center;
                    backface-visibility: hidden;                    
                    background-color: #d6d6d639;
                    @media(max-width:1450px){
                        padding: 30px;
                    }
                    @media(max-width:1250px){
                        padding: 20px;
                    }
                    @media(max-width:1000px){
                        padding: 10px;
                        border-radius: 12px;
                    }
                    @media(max-width:700px){
                        border-radius: 8px;
                    }                   
                    .img{
                        height: 400px;
                        display: flex;
                        overflow: hidden;
                        position: relative;
                        align-items: center;
                        justify-content: center;
                        @media(max-width:1450px){
                            height: 310px;
                        }
                        @media(max-width:1250px){
                            height: 270px;
                        }
                        @media(max-width:1000px){
                            height: 220px;
                        }
                        @media(max-width:700px){
                            height: 180px;
                        }
                        @media(max-width:500px){
                            height: 130px;
                        }
                        @media(max-width:410px){
                            height: 100px;
                        }
                        img{
                            width: 90%;
                            height: 90%;
                            object-fit: cover;
                            position: absolute;
                            border-radius: 14px;
                            @media(max-width:1000px){
                                border-radius: 10px;
                            }
                            @media(max-width:700px){
                                width: 95%;
                                height: 95%;
                                border-radius: 7px;
                            }
                        }
                    }
                    .small-img{
                        height: 250px;
                        margin-top: 20px;
                        @media(max-width:1450px){
                            height: 180px;
                        }
                        @media(max-width:1250px){
                            height: 150px;
                        }
                        @media(max-width:1000px){
                            height: 130px;
                            margin-top: 10px;
                        }
                        @media(max-width:700px){
                            height: 110px;
                            margin-top: 5px;
                        }
                        @media(max-width:500px){
                            height: 90px;
                        }
                        @media(max-width:410px){
                            height: 70px;
                        }
                        img{
                            width: 90%;
                            height: 100%; 
                            @media(max-width:700px){                        
                                width: 95%;                                
                            }                           
                        }
                    }
                    .texts{
                        padding: 20px;
                        @media(max-width:700px){
                            padding: 10px;
                        }
                        @media(max-width:500px){
                            padding: 5px;
                        }
                        .name{
                            color: white;
                            text-align: left;
                            font-weight: 700;
                            text-transform: uppercase;
                            font-family: 'Jura', sans-serif;
                            @media(max-width:1250px){
                                font-size: 28px;
                            }
                            @media(max-width:1000px){
                                font-size: 25px;
                            }
                            @media(max-width:700px){
                                font-size: 20px;
                            }
                            @media(max-width:500px){
                                margin: 0;
                                font-size: 18px;
                                margin-bottom: 5px;
                            }
                            @media(max-width:410px){                                
                                font-size: 16px;
                                margin-bottom: 2px;
                            }
                        }
                        .desc{
                            color: white;
                            text-align: left;
                            margin-top: 20px;
                            font-weight: 500;
                            font-family: 'Jura', sans-serif;
                            @media(max-width:1250px){
                                font-size: 14px;
                                margin-top: 10px;
                            }
                            @media(max-width:700px){
                                font-size: 13px;
                                margin-top: 5px;
                            }
                            @media(max-width:500px){
                                margin: 0;
                                font-size: 11px;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}