.OurTeam{
    top: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: sticky;
    align-items: center;
    justify-content: center;
    background-color: white;
    @media (max-width:767px){
        height: auto;
        position: relative;
    }
    .wrapper{
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 0;
        @media (max-width:500px){
            padding-top: 0;
        }
        .title{
            margin-top: 30px;
            font-size: 3.3vw;
            color: #E1053F;
            font-weight: 700;
            text-align: center;
            font-family: 'Jura', sans-serif;
            @media (max-width:1200px){
                font-size: 3.8vw;
            }
            @media (max-width:700px){
                font-size: 4.5vw;
            }
            @media (max-width:500px){
                font-size: 5.5vw;
            }
            @media (max-width:400px){
                font-size: 6vw;
            }
        }
        .title-text{
            color: black;
            font-size: 20px;
            font-weight: 500;
            margin-top: 20px;
            text-align: center;
            font-family: 'Jura', sans-serif;
            @media (max-width:1200px){
                font-size: 18px;
            }
            @media (max-width:900px){
                font-size: 16px;
            }
            @media (max-width:700px){
                font-size: 15px;
            }
            @media (max-width:500px){
                font-size: 14px;
                margin-top: 10px;
            }
            @media (max-width:400px){
                font-size: 13px;                
            }
        }
        .imgs{
            top: 0;
            left: 0;
            width: 100%;
            height: 28vw;
            display: flex;
            margin-top: 170px;
            position: relative;
            justify-content: center;
            @media (max-width:1000px){
                margin-top: 150px;
                height: 25vw;
            }
            @media (max-width:700px){
                height: 25vw;
                margin-top: 100px;
            }
            @media (max-width:600px){
                height: 30vw;                
            }
            @media (max-width:500px){
                height: 35vw;                
            }
            @media (max-width:500px){
                height: 38vw;                
            }
            .col-3{
                height: 23vw;
                position: absolute;
                border: solid 2px black;
                @media (max-width:700px){
                    height: 25vw;
                }
                @media (max-width:600px){
                    width: 25vw;
                    height: 30vw;
                }
                @media (max-width:500px){
                    width: 28vw;
                    height: 34vw;
                }
                @media (max-width:400px){
                    width: 32vw;
                    height: 38vw;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    // filter: grayscale(95%);
                }
            }
            .img1{
                top: 50px;
                margin-left: 25vw;
                transform: rotate(45deg);
                @media (max-width:1000px){
                    top: 0px;                    
                }
                @media (max-width:600px){
                    margin-left: 30vw;                   
                }
                @media (max-width:500px){
                    margin-left: 40vw;   
                    transform: rotate(37deg);                
                }
                @media (max-width:400px){
                    margin-left: 45vw;                                    
                }
            }
            .img2{
                top: -70px;
                margin-left: 9vw;
                transform: rotate(25deg);
                @media (max-width:1000px){
                    top: -50px;                    
                }
                @media (max-width:600px){
                    margin-left: 13vw;                   
                }
                @media (max-width:500px){
                    margin-left: 17vw;                   
                }
                @media (max-width:400px){
                    top: -40px;
                    margin-left: 21vw;                   
                }
            }
            .img3{
                top: 50px;
                margin-left: -25vw;
                transform: rotate(-40deg);
                @media (max-width:1000px){
                    top: 0px;                    
                }
                @media (max-width:600px){
                    margin-left: -30vw;                   
                }
                @media (max-width:500px){
                    margin-left: -40vw;   
                    transform: rotate(-35deg);                
                }
                @media (max-width:400px){
                    margin-left: -45vw;                                    
                }
            }
            .img4{
                top: -100px;
                margin-left: -9vw;
                transform: rotate(-20deg);
                @media (max-width:1000px){
                    top: -70px;                    
                }
                @media (max-width:600px){
                    margin-left: -13vw;                   
                }
                @media (max-width:500px){
                    margin-left: -17vw;                   
                }
                @media (max-width:400px){
                    top: -60px;  
                    margin-left: -21vw;                   
                }
            }
        }
        .visibility{
            .col-3{
                display: none;
            }
            .img1{
                display: block;
            }
        }
    }
    .tape, .tape1{        
        z-index: 3;
        width: 300%;
        bottom: 100px;
        display: flex;
        color: white;
        padding: 10px 0;
        font-size: 22px;
        position: absolute;
        align-items: center;
        transform: rotate(5deg);
        background-color: transparent;
        justify-content: space-between;
        @media(max-width:1420px){                                                    
            width: 350%;
            padding: 7px 0;
        }
        @media(max-width:1050px){                                                    
            width: 430%;
            padding: 5px 0;
            bottom: 50px;
        }
        @media(max-width:600px){ 
            width: 700%;
            padding: 3px 0;
            font-size: 18px;
            transform: rotate(7deg);                                                   
        }
        @media(max-width:400px){                                                    
            padding: 0;        
            width: 850%;   
        }
        p{
            margin: 0;
            padding: 0;
            font-size: 14px;
            @media(max-width:500px){                                                    
                font-size: 12px;
            }
            @media(max-width:400px){                                                    
                font-size: 11px;
            }
        }
    }
    .tape1{
        z-index: 1;
        transform: rotate(-5deg);
        @media(max-width:600px){                                                                            
            transform: rotate(-7deg);
        }
    }
    .crimson, .grey{
        z-index: 2;
        width: 110%;
        bottom: 100px;
        display: flex;
        padding: 10px 0;
        font-size: 22px;
        color: transparent;
        position: absolute;
        align-items: center;
        transform: rotate(5deg);
        background-color: #E1053F;
        justify-content: space-between;
        @media(max-width:1420px){                                                    
            padding: 7px 0;
        }
        @media(max-width:1050px){                                                    
            padding: 5px 0;
            bottom: 50px;
        }
        @media(max-width:600px){                                                                
            padding: 3px 0;
            font-size: 18px;
            transform: rotate(7deg);
        }
        @media(max-width:400px){                                                                
            padding: 0;        
        }
        p{
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: transparent;
            @media(max-width:500px){                                                    
                font-size: 12px;
            }
            @media(max-width:400px){                                                    
                font-size: 11px;
            }
        }
    }
    .grey{
        z-index: 0;
        transform: rotate(-5deg);
        background-color: #8C8C8C;
        @media(max-width:600px){                                                                            
            transform: rotate(-7deg);
        }
    }
}